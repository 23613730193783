import $ from 'jquery'; // eslint-disable-line import/no-extraneous-dependencies

const header = document.querySelector('.header');

function initializeTooltips() {
    const allTooltips = document.querySelectorAll('.js-tooltips');
    const tooltipCount = allTooltips.length > 0;

    if (tooltipCount) {
        import(/* webpackChunkName: 'renderTooltips' */ '../helpers/tooltip/renderTooltips').then(
            ({ default: renderTooltips }) => {
                const phoneElement = document.querySelector('.js-tooltips-header-phone');

                renderTooltips(phoneElement);
            }
        );
    }
}

async function initializeUserArea() {
    const triggers = document.querySelectorAll('.profile-slider-trigger');

    if (triggers.length) {
        import(/* webpackChunkName: 'handleUserAreaClick' */ '../helpers/handleUserAreaClick/handleUserAreaClick').then(({default: handleUserAreaClick}) => {
            handleUserAreaClick(triggers);
        })
    }
}

export const toggleMenu = () => {
    header.classList.toggle('mobile-menu-close');
    header.classList.toggle('mobile-menu-open');
}

function initializeMobileMenu() {
    const mobileMenuButtonElement = document.getElementById('fw-mobile-menu-button');
    const mobileOverlay = document.getElementById('fw-mobile-menu-overlay');

    if (mobileMenuButtonElement) {
        mobileMenuButtonElement.addEventListener('click', toggleMenu);

        window.addEventListener('toggleMobileMenu', toggleMenu);
    }

    if(mobileOverlay) {
        mobileOverlay.addEventListener('click', toggleMenu);
    }
}

export default () => {
    initializeTooltips();

    if(!header.classList.contains('minimal')) {
        initializeMobileMenu();
        initializeUserArea();
    }
};

