import classNames from 'classnames';
import React from 'react';

type DefaultSpritemap = {
    spritemap?: 'medium';
    symbol:
        | 'chevron-right'
        | 'chevron-up'
        | 'chevron-up-short'
        | 'chevron-down-short'
        | 'arrow-down-bound'
        | 'check'
        | 'profile'
        | 'phone'
        | 'winter'
        | 'phone-call'
        | 'suitcase'
        | 'arrow-down-bound-circled'
        | 'star'
        | 'history'
        | 'time'
        | 'bin'
        | 'lock'
        | 'lock-off'
        | 'arrow-left-circled'
        | 'info-circle'
        | 'menu'
        | 'calendar'
        | 'leaf'
        | 'shield'
        | 'suitcase-filled'
        | 'bin-filled'
        | 'heart'
        | 'bed-double'
        | 'view'
        | 'view-off'
        | 'tick-circle'
        | 'close-circle'
        | 'phone-call-filled'
        | 'star-3'
        | 'star-2'
        | 'close'
        | 'arrow-right-bound'
        | 'chevron-left'
        | 'arrow-down'
        | 'plus'
        | 'arrow-up'
        | 'alert'
        | 'load'
        | 'search'
        | 'arrow-left-bound'
        | 'arrow-right'
        | 'share'
        | 'minus'
        | 'arrow-left'
        | 'apple'
        | 'view-off-filled'
        | 'profile-filled'
        | 'view-filled'
        | 'info-circle-filled'
        | 'world'
        | 'person-circle-outlined'
        | 'location'
        | 'shield-filled'
        | 'facebook'
        | 'google'
        | 'hotel'
        | 'suitcase-small'
        | 'suitcase-small-filled'
        | 'phone-filled-rounded'
        | 'heart-filled'
        | 'chevron-down'
        | 'person-circle-filled'
        | 'phone-circle'
        | 'suitcase-long'
        | 'suitcase-rounded'
        | 'heart-medium'
        | 'heart-medium-filled'
        | 'circle-double'
        | 'smith-ampersand';
};

type LargeSpritemap = {
    spritemap: 'large';
    symbol:
        | 'cart'
        | 'people'
        | 'profiles'
        | 'profiles-2'
        | 'profiles-2-filled'
        | 'profiles-filled'
        | 'menu'
        | 'arrow-left-large'
        | 'arrow-right-thin'
        | 'close'
        | 'cart-filled'
        | 'building'
        | 'credit-card'
        | 'person'
        | 'bed-double-large';
};

declare global {
    interface Window {
        iconSpriteMedium: string;
        iconSpriteLarge: string;
    }
}

export type MediumIconProps = React.SVGAttributes<SVGSVGElement> & DefaultSpritemap;
export type LargeIconProps = React.SVGAttributes<SVGSVGElement> & LargeSpritemap;

export type IconProps = MediumIconProps | LargeIconProps;

const Icon = React.forwardRef<SVGSVGElement, IconProps>(
    ({ className, spritemap = 'medium', symbol, ...otherProps }: IconProps, ref) => {
        const iconsPath = spritemap === 'medium' ? window.iconSpriteMedium : window.iconSpriteLarge;

        return (
            <svg
                {...otherProps}
                className={classNames('fw-icon-sprite', className, {
                    large: spritemap === 'large',
                })}
                ref={ref}
                role="presentation"
            >
                <use xlinkHref={`${iconsPath}${symbol}`} />
            </svg>
        );
    }
);

Icon.displayName = 'Icon';

export default Icon;

