import React from 'react';

interface ButtonProps {
    text?: string,
    children?: Element,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    name?: string,
    type?: 'submit'|'reset'|'button',
    value?: string,
    isDisabled?: boolean,
    classes?: string,
    ariaLabel?: string,
    ariaPressed?: boolean,
}

const Button: React.SFC<ButtonProps> = ({
    name,
    type,
    value,
    text,
    children,
    onClick,
    isDisabled,
    classes,
    ariaLabel,
    ariaPressed
}) => (
    <button
        name={name}
        type={type}
        value={value}
        onClick={onClick}
        disabled={isDisabled}
        className={`c-ui-button ${classes}`}
        aria-label={ariaLabel}
        aria-pressed={ariaPressed}
    >
        {children}{text}
    </button>
);

/**
* propTypes
* @property {string} text the text showing up on the button
* @property {function} onClick the on click function on the button
* @property {string} name the name attribute of the button
* @property {string} value the value of the button
* @property {boolean} isDisabled the disabled state of the button
* @property {string} classes the class name of the button
* @property {string} ariaLabel the aria label text of the button
* @property {boolean} ariaPressed the aria pressed state of the button
* */

Button.defaultProps = {
    onClick: () => {},
    name: '',
    type: 'button',
    value: '',
    isDisabled: false,
    classes: '',
    text: '',
    ariaLabel: '',
    ariaPressed: false,
};

export default Button;
