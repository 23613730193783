import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import InputTypes from '../../../common/components/InputTypes';
import api from '../../../common/services/api';
import GoogleLogin from './GoogleLogin';
import FacebookLogin from './FacebookLogin';
import { createCookie } from '../../../utilities/cookie';
import { LOGIN_CHANNEL_SMITH } from '../../../common/constants/global';
import { LoginModes } from '../constants/login';
import PasswordInput from '../../../common/components/PasswordInput';

export default class LoginForm extends Component {
    /**
     * propTypes
     *
     * @property {string} returnLink return url
     * @property {bool} allowSocialAuth display social login buttons
     */
    static get propTypes() {
        return {
            returnLink: PropTypes.string,
            allowSignup: PropTypes.bool,
            allowSocialAuth: PropTypes.bool.isRequired
        };
    }

    static defaultProps = {
        returnLink: '/basket',
        allowSignup: true
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            formFields: {
                email: '',
                password: '',
                remember: false
            },
            isProcessing: false,
            message: ''
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onCheckHandler = this.onCheckHandler.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
        this.handleEnterSubmit = this.handleEnterSubmit.bind(this);
        this.handleDataLayer = this.handleDataLayer.bind(this);
    }

    componentDidMount() {
        const signUpLink = document.querySelector('.signUpLink');

        if (signUpLink) {
            signUpLink.onclick = () => {
                event.preventDefault();
                this.props.changeFormAction();
            };
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pop_up_shown',
            pop_up_type: 'sign_in'
        });
    }

    componentWillUnmount() {
        window.dataLayer.push({
            event: 'pop_up_closed',
            pop_up_type: 'sign_in'
        });
    }

    onChangeHandler(event) {
        const field = event.target.name;
        const { formFields, formFieldsInteraction } = this.state;

        if (!formFieldsInteraction?.[field]) {
            this.handleDataLayer(field);
            this.setState({
                formFieldsInteraction: {
                    ...formFieldsInteraction,
                    [field]: true,
                },
            });
        }

        this.setState({
            formFields: Object.assign({}, formFields, {
                [field]: event.target.value
            })
        });
    }

    onCheckHandler(event) {
        const field = event.target.name;
        const { formFields } = this.state;

        this.setState({
            formFields: Object.assign({}, formFields, {
                [field]: event.target.checked
            })
        });
    }

    handleDataLayer(fieldType) {
        window.dataLayer.push({
            event: 'pop_up_form_field',
            pop_up_type: 'sign_in',
            pop_up_field_name: fieldType
        });
    }

    submitLogin(event) {
        event.preventDefault();

        const { validateForm, trigger, onSuccess } = this.props;
        const { formFields } = this.state;

        const { form } = event.target;

        window.dataLayer.push({
            event: 'pop_up_submit',
            pop_up_type: 'sign_in'
        });

        validateForm(form).then((errors) => {
            const numberOfErrors = Object.keys(errors).reduce((acc, curr) => {
                if (errors[curr] !== '') {
                    acc += 1;
                }
                return acc;
            }, 0);

            if (numberOfErrors === 0) {
                this.setState({ isProcessing: true });
                api.checkLogin(formFields).then((response) => {
                    if (response.status === false) {
                        this.setState({
                            isProcessing: false,
                            message: response.message
                        });
                    } else if (trigger) {
                        $(`.${trigger}`).trigger('click');
                    } else {
                        onSuccess();
                        createCookie('loginChannel', LOGIN_CHANNEL_SMITH, 30);
                    }
                }).catch((err) => {
                    this.setState({
                        isProcessing: false,
                        message: 'Please check you details and try again.'
                    });
                });
            }
        });
    }

    showMessage() {
        const { message } = this.state;

        if (message !== '') {
            return <p className="c-authentication__error">{ message }</p>;
        }
    }

    handleEnterSubmit(event) {
        if (event.which === 13 || event.keyCode === 13) {
            event.preventDefault();
            this.submitButton.click();
            return false;
        }
        return true;
    }

    renderLoginForm() {
        const { formFields: { email, password, remember }, isProcessing } = this.state;

        const { onBlurValidation, formErrors, showForgot } = this.props;

        return (
            <form className="c-loginForm__form clearfix">
                <InputTypes
                    name="email"
                    value={email}
                    isRequired={true}
                    placeHolder="Email"
                    label="Email"
                    pattern="(@)(.+)$"
                    onChange={this.onChangeHandler}
                    className="border-none"
                    onBlur={onBlurValidation}
                    error={formErrors.email}
                />
                <PasswordInput>
                    <InputTypes
                        name="password"
                        value={password}
                        type="password"
                        isRequired={true}
                        placeHolder="Password"
                        label="Password"
                        onChange={this.onChangeHandler}
                        onBlur={onBlurValidation}
                        error={formErrors.password}
                        keydown={this.handleEnterSubmit}
                        maxlength={128}
                    />
                </PasswordInput>
                <div className="c-loginForm__checkbox">
                    <InputTypes
                        layout="inverted"
                        name="remember"
                        value={remember}
                        checked={remember}
                        type="checkbox"
                        placeHolder="Remember me"
                        label="Remember me"
                        onChange={this.onCheckHandler}
                    />
                </div>
                <a className="c-loginForm__forgotPassword" onClick={showForgot}>Forgotten password?</a>
                { this.showMessage() }
                <button
                    className="c-authentication__cta"
                    disabled={isProcessing}
                    onClick={this.submitLogin}
                    ref={(submitButton) => { this.submitButton = submitButton; }}
                >
                    Sign in
                </button>
            </form>
        );
    }

    renderFooter() {
        const { changeFormAction } = this.props;
        return (
            <footer className="c-authentication__footer">
                <p className="c-authentication__footerText">Not a member?</p>
                <a className="c-authentication__footerAction" role="button" onClick={changeFormAction}>Join the Club</a>
            </footer>
        );
    }

    renderSocialAuth() {
        const { allowSocialAuth, onSuccess } = this.props;

        return (
            <Fragment>
                { allowSocialAuth
                    && (
                        <Fragment>
                            <p className="c-authentication__exception">Or you can</p>
                            <FacebookLogin
                                onSuccess={onSuccess}
                                mode={LoginModes.login}
                            />
                            <GoogleLogin
                                onSuccess={onSuccess}
                                mode={LoginModes.login}
                            />
                        </Fragment>
                    )
                }
            </Fragment>
        );
    }

    render() {
        const { headerMessage, allowSignup } = this.props;

        return (
            <Fragment>
                <section className="c-authentication__content">
                    <h2 className="c-authentication__title">Sign in</h2>
                    <p dangerouslySetInnerHTML={ {__html: headerMessage } } className="c-authentication__headerMessage"></p>
                    {this.renderLoginForm()}

                    {this.renderSocialAuth()}
                </section>
                {allowSignup && this.renderFooter()}
            </Fragment>
        );
    }
}
