import createObserver from './createObserver';

const updateSrcSets = (source) => { source.setAttribute('srcset', source.getAttribute('data-picturesrc')); };

const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
        const pictureElement = entry.target;
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const pictureSources = pictureElement.querySelectorAll('source');
            const imageFallback = pictureElement.querySelector('img');
            const imageLazySrc = imageFallback.getAttribute('data-picturesrc');

            if (imageLazySrc) {
                const imagePictureSrc = imageFallback.getAttribute('data-picturesrc').split('1x,')[0];
                const withoutResolutionModifier = imagePictureSrc.replace(/(\s\d?x)$/, '');
                imageFallback.src = withoutResolutionModifier;
            }

            [...pictureSources].map(updateSrcSets);
            observer.unobserve(pictureElement);
        }
    });
};

const observer = callback => elements => createObserver(elements, callback, { threshold: 0 });

export default observer(observerCallback);
